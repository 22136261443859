import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import CarouselComponent from './CarouselComponent';
import AddToCart from './AddToCart';
import ContactForm from './ContactForm';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import About from './About';
import Home from './Home';
import Trails from './Trails';
import Tips from './Tips';
import Footer from './Footer';
import usePageTracking from './usePageTracking';
import NorthAmerica from './NorthAmerica';
import Europe from './Europe';
import Asia from './Asia';
import GenerativeAIForm from './GenerativeAIForm';
import LoginButton from './components/LoginButton'; // Ensure you have this component
import ReactGA from 'react-ga';

function App() {
  const location = useLocation();
  
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <div className="App">
      <nav className="nav">
        <Link to="/" className="nav-item">Home</Link>
        <Link to="/about" className="nav-item">About Me</Link>
        <div className="nav-item mega-nav">
          <span>Trails</span>
          <div className="mega-content">
            <Link to="/trails/north-america">North America</Link>
            <Link to="/trails/europe">Europe</Link>
            <Link to="/trails/asia">Asia</Link>
          </div>
        </div>
        <Link to="/tips" className="nav-item">Tips</Link>
        <Link to="/contact" className="nav-item">Contact</Link>
        <LoginButton className="nav-item" /> {/* Add LoginButton here */}
      </nav>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/trails/north-america" element={<NorthAmerica />} />
        <Route path="/trails/europe" element={<Europe />} />
        <Route path="/trails/asia" element={<Asia />} />
        <Route path="/tips" element={<Tips />} />
        <Route path="/contact" element={<ContactForm />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
