// src/Asia.js
import React from 'react';
import MyImage from './assets/k12.webp';

const Asia = () => {
  return (
    
    <div>
      <h1>Asia</h1>
      <p>Here is some information about me.</p>
      <img
        className="d-block w-100"
        
        src={MyImage}
        alt="Manish"
      />
    </div>
  );
};

export default Asia;
