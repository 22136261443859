// src/Home.js
import React from 'react';
import { Carousel } from 'react-bootstrap';
import CarouselComponent from './CarouselComponent';

const Home = () => {
  return (
    <div className= "Home"> 
      <h1>Welcome to My Hiking Experience</h1>
      <CarouselComponent />
    </div>
  );
};

export default Home;
