import React from 'react';
import MyImage from './assets/k12.webp';

function Europe() {
  return (
    
        <div>
          <h1>Europe</h1>
          <p>Here is some information about me.</p>
          <img
            className="d-block w-100"
            src={MyImage}
            alt="Manish"
          />
        </div>
      );
}

export default Europe;