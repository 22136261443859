import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-links">
        <Link to="/" className="footer-link">Home</Link>
        <Link to="/about" className="footer-link">About Me</Link>
        <Link to="/trails" className="footer-link">Trails</Link>
        <Link to="/tips" className="footer-link">Tips</Link>
        <Link to="/contact" className="footer-link">Contact</Link>
      </div>
      <div className="footer-socials">
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="footer-social">Facebook</a>
        <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="footer-social">Twitter</a>
        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="footer-social">Instagram</a>
      </div>
      <div className="footer-copyright">
        &copy; {new Date().getFullYear()} My Hiking Experience. All Rights Reserved.
      </div>
    </footer>
  );
}

export default Footer;
