// src/Tips.js
import React from 'react';

const Tips = () => {
  return (
    <div>
      <h1>Tips</h1>
      <p>Here are some hiking tips I've learned.</p>
    </div>
  );
};

export default Tips;
