import React from 'react';
import { Carousel } from 'react-bootstrap';
import './CarouselComponent.css';

const CarouselComponent = () => {
  return (
    <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/k6.webp"
          alt="Norway"
        />
        <Carousel.Caption>
          <h3>KJeragbolten</h3>
          <p>One of the most amazing places in the world</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/k7.webp"
          alt="Czechia"
        />
        <Carousel.Caption>
          <h3>Mount Baldy</h3>
          <p>Snow Hiking is always refreshing.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/k8.webp"
          alt="Goa"
        />
        <Carousel.Caption>
          <h3>Czechia</h3>
          <p>Best place to Party!</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/k9.webp"
          alt="Amsterdam"
        />
        <Carousel.Caption>
          <h3>Amsterdam</h3>
          <p>Beautiful Canals!</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/k11.webp"
          alt="Zion"
        />
        <Carousel.Caption>
          <h3>Mount Whitney</h3>
          <p>The Tallest Mountain in California.</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default CarouselComponent;
