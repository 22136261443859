// src/AddToCart.js
import React, { useState } from 'react';

function AddToCart() {
  const [disabled, setDisabled] = useState(false);

  const handleClick = () => {
    setDisabled(true);
  };

  return (
    <button onClick={handleClick} disabled={disabled}>
      Click Me
    </button>
  );
}

export default AddToCart;
